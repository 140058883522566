import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 2,
    width: '40px',
    height: '40px',
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  checkedIcon: {
    backgroundColor: '#1D76D3',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'unset',
    '&:before': {
      display: 'block',
      width: 40,
      height: 40,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M6.61 11.89L3.5 8.78 2.44 9.84 6.61 14l8.95-8.95L14.5 4z'" + 
        " fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#1D76D3',
    },
  },
});

const ToggleField = ({
  label,
  input,
  meta,
  ...props
}) => {
  const classes = useStyles();
  return(
    <FormControl {...props}>
      {label && (
        <InputLabel shrink htmlFor={input.name} >
          {label}
        </InputLabel>
      )}
      <Box
        marginTop="20px"
        height={50}
      >
        <Checkbox
              {...input}
              icon={<span className={classes.icon} />}
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              color="primary"
            />
      </Box> 
    </FormControl>
  )
};

export default ToggleField;