import formatString from "format-string-by-pattern";

const phoneFormatting = (value) => {
  const firstChar = value.charAt(0)
  if (firstChar == "+"){
    return value
  } else {
    return formatString("(AAA) AAA-AAAA xBBBBBBBB", value)
  }
}

export const formatCurrency = (value) => {
  const dollars = parseInt(value)
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,      
    maximumFractionDigits: 0,
  }).format(dollars);
};

export const getObjectValue = (options, value) => {
  if (options && typeof(value) == 'string') {
    return options.find(el => el.value === value)
  } else {
    return value;
  }
};

export default phoneFormatting;