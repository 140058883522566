import React, { useState, useEffect } from "react";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AssistantIcon from '@material-ui/icons/Assistant';
import phoneFormatting from '../../../utils/formatting';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  divider: {
    height: 40,
    margin: 4,
  },
  input: {
    flex: 1,
    height: 48
  }
}));

const PhoneField = ({
  label,
  helperText,
  multiline,
  rows,
  input,
  meta,
  autoFocus,
  placeholder,
  ...props
}) => {
  const [isFocus, setFocus] = useState(false);
  const hasError = meta? meta.error && meta.touched : null;
  const classes = useStyles();
  const [formatter, setFormatter] = useState(false);

  const handlePhoneFormatting = () => {
    input.onChange(phoneFormatting(input.value))
  }

  useEffect(() => {
    if(input.value != phoneFormatting(input.value)){
      setFormatter(true);
    } else {
      setFormatter(false);
    }
  },[input.value])
  return (
    <FormControl {...props}
      error={hasError}
      className={classes.root}
    >
      {label && (
        <InputLabel shrink htmlFor={input? input.name: null} focused={isFocus}>
          {label}
        </InputLabel>
      )}
      <Box
        border={1}
        borderColor={isFocus ? "primary.main" : hasError ? "error.main" : "#c4c4c4"}
        paddingLeft={1}
        paddingRight={1}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        width={1}
        display="flex"
        flex={1}
        flexDirection="row"
        // marginLeft={1}
        marginTop={label ? "24px" : 0}
        // height={50} 
        height={multiline? 'unset' : 50}
        borderRadius={6}
        // marginBottom={"16px"}
        justifyContent="space-between"
      >
        <InputBase
          {...input}
          id={input.name}
          aria-describedby={`${input.name}-helper-text`}
          multiline={multiline}
          rows={rows}
          placeholder={placeholder}
          autoFocus={autoFocus}
          className={classes.input}
        />
        <If condition={formatter}>
          <Divider className={classes.divider} orientation="vertical" />
          <Tooltip title="Format">
            <IconButton color="primary" className={classes.iconButton} aria-label="auto-format" onClick={() => handlePhoneFormatting()}>
              <AssistantIcon />
            </IconButton>
          </Tooltip>
        </If>
      </Box>
      {/* {(helperText || meta.error) && ( */}
        <FormHelperText id={`${input.name}-helper-text`}>
          <Choose>
            <When condition={meta.error && meta.touched}>{meta.error}</When>
            <When condition={helperText}>{helperText}</When>
          </Choose>
        </FormHelperText>
      {/* )} */}
    </FormControl>
  );
};


export default PhoneField;
