import React, { useState, useRef, useEffect } from 'react';
import ReactSelect from 'react-select';

import InputLabel from "@material-ui/core/InputLabel";

import { useDivisions } from '../../utils/crm/use-division';

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '53px',
    lineHeight: '24px',
    marginTop: '3px',
    borderColor: '#E3E3E3',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 9999
  })
}

const DivisionSelectField = ({
  input,
  meta,
  label,
  required = false,
  helperText,
  ...rest
}) => {
  const [{ divisions }] = useDivisions();
  const [menuPlacement, setMenuPlacement] = useState('auto');
  const selectRef = useRef(null);

  useEffect(() => {
    const updateMenuPlacement = () => {
      if (selectRef.current) {
        const rect = selectRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        if (spaceBelow < 200) {
          setMenuPlacement('top');
        } else {
          setMenuPlacement('bottom');
        }
      }
    };
    // Call it on mount and whenever the window resizes
    updateMenuPlacement();
    window.addEventListener('resize', updateMenuPlacement);
    return () => {
      window.removeEventListener('resize', updateMenuPlacement);
    };
  }, []);

  return(
    <React.Fragment>
      <div ref={selectRef}>
        <If condition={label}>
          <InputLabel 
            shrink 
            variant="standard" 
            required={required}
            sx={{
              transform: 'translate(0, -4px)',
              fontSize: '0.86em',
              color: '#000',
            }}
          >
            {label}
          </InputLabel>
        </If>
        <ReactSelect
          options={divisions}
          {...input}
          {...rest}
          isMulti
          closeMenuOnSelect={false}
          styles={customStyles}
          required={required}
          menuPortalTarget={document.body}
          menuPlacement={"bottom"}
        />
      </div>
    </React.Fragment>
  )
};
export default DivisionSelectField;