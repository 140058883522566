import { useState, useEffect } from "react";
import sortBy from "lodash/sortBy";
import { API, graphqlOperation} from "aws-amplify";

import { default as operations } from "../../redux/actions/amplifyActions";
import { default as useActions } from "../use-actions";
import { onCreateDivision, onDeleteDivision, onUpdateDivision } from "../../src";

export const useDivisions = (triggerFetch) => {
  const { listDivisions } = useActions(operations);
  const [divisions, setDivisions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(null);

  const fetchDivisions = async () => {
    setIsLoading(true);
    try{
      const data = await listDivisions({ limit: 100 });
      // console.log(data);
      setDivisions(sortBy(data.value.items, ["createdAt"]));
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }catch(e) {
      console.log(e);
      setDivisions([]);
    }
  };

  useEffect(() => { fetchDivisions();}, [triggerFetch]);

  return [{ divisions, isLoading, total}]
};


export const useCreateDivision = () => {
  const { createDivision } = useActions(operations);

  return async values => {
    const data = await createDivision({ input: {
      ...values
    }})
    return data;
  }
}

export const useOnCreateDivision = callback => {
  return API.graphql(graphqlOperation(onCreateDivision)).subscribe({
    next: callback,
    error: error => console.log(error)
  });
}

export const useUpdateDivision = () => {
  const { updateDivision } = useActions(operations);

  return async values => {
    return await updateDivision({ input: {
      id: values.id,
      label: values.label,
    }});
  }
}

export const useDeleteDivision = () => {
  const {
    useGetDivision,
    deleteDivision,
    deleteContactDivision,
    deleteCompanyDivision,
  } = useActions(operations);
  
  return async divisionId => {
    try {

      // Remove Contact Divisions
      let divisionContactsToRemove = [];
      let tokenContact = null;

      while(true){
        const data = await useGetDivision({ id: divisionId, contactsNextToken: tokenContact});
        tokenContact = data.value.getDivision.contacts.nextToken;
        divisionContactsToRemove = divisionContactsToRemove.concat(data.value.getDivision.contacts.items)
        if(tokenContact === null) break;
      }
      divisionContactsToRemove.map(async (division) => {
        await deleteContactDivision({ input: { id: division.id } }) 
      })

      // Remove Company Divisions
      let divisionCompaniesToRemove = [];
      let tokenCompany = null;

      while(true){
        const data = await useGetDivision({ id: divisionId, companiesNextToken: tokenCompany});
        tokenCompany = data.value.getDivision.companies.nextToken;
        divisionCompaniesToRemove = divisionCompaniesToRemove.concat(data.value.getDivision.companies.items)
        if(tokenCompany === null) break;
      }

      divisionCompaniesToRemove.map(async (division) => {
        await deleteCompanyDivision({ input: { id: division.id } }) 
      })

		} catch(e) {
			console.log(e);
		}
   await deleteDivision({ input: { id: divisionId }})
  }
}

export const useOnDeleteDivision = callback => {
  return API.graphql(graphqlOperation(onDeleteDivision)).subscribe({
    next: callback,
    error: error => console.log(error)
  });
}

export const useOnUpdateDivision = callback => {
  return API.graphql(graphqlOperation(onUpdateDivision)).subscribe({
    next: callback,
    error: error => console.log(error)
  });
}